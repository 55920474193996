import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { TopicData } from '../types';

type Props = {
  open: boolean;
  onClose: () => void;
  onAction: (topic: TopicData) => Promise<void>;
  availableTopics: TopicData[];
};

type FormValues = {
  topicId: string;
};

export default function AddTopicToSiteDialog({
  open,
  onClose: handleClose,
  onAction: handleAction,
  availableTopics,
}: Props) {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    defaultValues: { topicId: '' },
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const selectedTopic = availableTopics.find(
        (topic) => topic.id === data.topicId,
      );
      if (!selectedTopic) {
        setError('topicId', {
          type: 'required',
          message: 'No topic selected',
        });

        return;
      }

      try {
        await handleAction(selectedTopic);
      } finally {
        reset();
        handleClose();
      }
    },
    [handleAction, handleClose, reset, availableTopics, setError],
  );

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>Select a topic</DialogTitle>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ marginTop: 1 }}>
            <FormControl fullWidth variant='outlined' error={!!errors.topicId}>
              <InputLabel>Topic</InputLabel>
              {availableTopics.length > 0 ? (
                <Controller
                  name='topicId'
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label='Topic'>
                      {availableTopics.map((topicData) => (
                        <MenuItem key={topicData.id} value={topicData.id}>
                          {topicData.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              ) : (
                <>
                  <Select value='' disabled></Select>
                  <Typography sx={{ color: 'gray' }}>
                    No topics available
                  </Typography>
                </>
              )}
              <FormHelperText>{errors.topicId?.message}</FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <LoadingButton
            type='submit'
            color='primary'
            variant='contained'
            size='small'
            loading={isSubmitting}
            disabled={isSubmitting || availableTopics.length === 0}
          >
            Add
          </LoadingButton>
          <Button
            onClick={() => {
              handleClose();
              reset();
            }}
            size='small'
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
