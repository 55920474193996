import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {
  Box,
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import ChatContainer from '../components/ChatContainer';
import { Message, SiteData, ChatSessionSource } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import { chatsTheme } from '../theme/theme';
import useChatSession from '../hooks/UseChatSession';
import { styles } from '../styles/stylesheet';
import { PAGE_SIZE_FOR_MESSAGES } from '../constants';

const PublicChat = () => {
  const { publicChatId } = useParams();
  const { apiClient } = useApiClient();

  const [site, setSite] = useState<SiteData>();
  const [loading, setLoading] = useState(true);

  const { chatSessionId, updateChatSessionId } = useChatSession(
    site?.id,
    ChatSessionSource.Public,
  );

  // TODO: Refactor common code
  const fetchSite = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/by-public-chat-id/${publicChatId}`,
      );

      setSite(data.site);
    } catch (error) {
      console.error('Error fetching site data:', error);
    } finally {
      setLoading(false);
    }
  }, [publicChatId, apiClient]);

  const sendMessageResponse = async (
    userInput: string,
  ): Promise<axios.AxiosResponse<any, any>> => {
    const response = await apiClient.post(
      `${process.env.REACT_APP_BACKEND_URL}/chats/public-chats/${publicChatId}`,
      {
        chatSessionId,
        userInput,
        status: site!.status,
        type: 'sites',
      },
      { headers: { 'Content-Type': 'application/json' } },
    );

    return response;
  };

  const fetchMessages = useCallback(
    async (chatSessionId: string, page: number): Promise<Message[]> => {
      try {
        if (!site) {
          return [];
        }

        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/by-chat-session-id/${chatSessionId}?siteId=${site.id}&pageSize=${PAGE_SIZE_FOR_MESSAGES}&pageNumber=${page}`,
        );

        const messages = response.data.messages.reverse();

        return messages;
      } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
      }
    },
    [apiClient, site],
  );

  useEffect(() => {
    fetchSite();
  }, [fetchSite]);

  useEffect(() => {
    updateChatSessionId();
  }, [updateChatSessionId]);

  if (loading || !site || chatSessionId === '') {
    return (
      <Box sx={styles.display}>
        {loading ? (
          <CircularProgress />
        ) : !site || chatSessionId === '' ? (
          <Typography variant='h5'>Site not found</Typography>
        ) : null}
      </Box>
    );
  }

  return (
    <ThemeProvider
      theme={chatsTheme}
      defaultMode='system'
      modeStorageKey={`mui-mode-${site.id}`}
    >
      <Grid
        size={{ xs: 12, md: 12 }}
        height='calc(100vh)'
        top={0}
        position='sticky'
      >
        <ChatContainer
          id={site.id}
          status={site.status}
          theme={site.extensionTheme}
          onFetchMessages={fetchMessages}
          onSendMessage={sendMessageResponse}
          chatSessionId={chatSessionId}
          isOpen={true}
          onNewChat={updateChatSessionId}
          chatHeaderProps={{
            isFullScreen: false,
            showTitle: true,
            showNewChat: true,
            isTitleClickable: true,
            subTitle: {
              text: site.title,
              url: site.url,
            },
          }}
          source={ChatSessionSource.Public}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default PublicChat;
