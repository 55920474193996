import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotifications } from '@toolpad/core';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import SiteDetailsCard from '../../../components/SiteDetailsCard';
import PagesTable from '../../../components/PagesTable';
import ChatPanel from '../../../components/ChatPanel';
import {
  ChatSessionSource,
  ExtensionTheme,
  Message,
  SiteData,
  Status,
  TopicData,
} from '../../../types';
import { useApiClient } from '../../../context/ApiClientContext';
import { styles } from '../../../styles/stylesheet';
import { SITE_POLL_INTERVAL } from '../../../constants';
import axios from 'axios';
import useChatSession from '../../../hooks/UseChatSession';
import { PAGE_SIZE_FOR_MESSAGES } from '../../../constants';
import SiteChatStatus from '../../../components/SiteChatStatus';

function SitePage() {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isReIndexDialogOpen, setIsReIndexDialogOpen] = useState(false);
  const [site, setSite] = useState<SiteData>();
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState<TopicData[]>([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const notifications = useNotifications();
  const { apiClient } = useApiClient();

  const { updateChatSessionId, chatSessionId } = useChatSession(
    id,
    ChatSessionSource.Dashboard,
  );

  useEffect(() => {
    updateChatSessionId();
  }, [updateChatSessionId]);

  const siteStatus: Status | undefined = useMemo(() => {
    if (!site) {
      return undefined;
    }

    return site.status;
  }, [site]);

  const fetchSite = useCallback(async () => {
    try {
      const [siteResponse, pagesCountResponse] = await Promise.all([
        apiClient.get(`${process.env.REACT_APP_BACKEND_URL}/sites/${id}`),
        apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/pages/count`,
        ),
      ]);

      setSite(siteResponse.data.site);
      setPagesCount(pagesCountResponse.data.pagesCount);
    } catch (error) {
      console.error('Error fetching site data:', error);
    } finally {
      setLoading(false);
    }
  }, [id, apiClient]);

  const onTopicAdd = (topic: TopicData) => {
    setTopics((prevTopics) => [...(prevTopics || []), topic]);
  };

  const onTopicRemove = (topic: TopicData) => {
    setTopics((prevTopics) =>
      (prevTopics || []).filter((prevTopic) => prevTopic !== topic),
    );
  };

  const fetchTopics = useCallback(async () => {
    try {
      const [topicsResponse] = await Promise.all([
        apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/topics/names?siteId=${id}`,
        ),
      ]);

      setTopics(topicsResponse.data.topics);
    } catch (error) {
      console.error('Error fetching sites data:', error);
    }
  }, [apiClient, id]);

  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  const fetchChatSessionsIds = async (
    pageNumber: number,
  ): Promise<axios.AxiosResponse<any, any>> => {
    const response = await apiClient.get(
      `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/chat-sessions?pageNumber=${pageNumber}&pageSize=${7}`,
    );

    return response;
  };

  const fetchChatSessionsCount = async (): Promise<
    axios.AxiosResponse<any, any>
  > => {
    const chatSessionCountResponse = await apiClient.get(
      `${process.env.REACT_APP_BACKEND_URL}/sites/${id}/chat-sessions/count`,
    );

    return chatSessionCountResponse;
  };

  const updateSiteTheme = async (
    selectedTheme: ExtensionTheme,
  ): Promise<void> => {
    await apiClient.patch(`${process.env.REACT_APP_BACKEND_URL}/sites/${id}`, {
      extensionTheme: selectedTheme,
    });
  };

  const sendMessageResponse = async (
    userInput: string,
  ): Promise<axios.AxiosResponse<any, any>> => {
    const response = await apiClient.post(
      `${process.env.REACT_APP_BACKEND_URL}/chats/sites/${id}`,
      { chatSessionId, userInput, status: site!.status },
      { headers: { 'Content-Type': 'application/json' } },
    );

    return response;
  };

  const fetchMessages = useCallback(
    async (chatSessionId: string, page: number): Promise<Message[]> => {
      try {
        if (!site) {
          return [];
        }

        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/by-chat-session-id/${chatSessionId}?siteId=${id}&pageSize=${PAGE_SIZE_FOR_MESSAGES}&pageNumber=${page}`,
        );

        const messages = response.data.messages.reverse();

        return messages;
      } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
      }
    },
    [apiClient, id, site],
  );

  const handleDelete = useCallback(async () => {
    try {
      const response = await apiClient.delete(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${id}`,
      );

      const isSiteDeleted = response.data.isSiteDeleted;

      if (isSiteDeleted) {
        notifications.show('Site Deleted!', {
          severity: 'success',
          autoHideDuration: 3000,
        });
        navigate('/dashboard');
      } else {
        notifications.show(
          'An error occurred while deleting the site. Please try again.',
          {
            severity: 'error',
            autoHideDuration: 3000,
          },
        );
      }
    } catch (error) {
      notifications.show('An error occurred while making the request.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
      console.error(error);
    }

    setIsDeleteDialogOpen(false);
  }, [id, apiClient, navigate, notifications]);

  const handleReIndexing = async () => {
    try {
      await apiClient.post(
        `${process.env.REACT_APP_BACKEND_URL}/sites/re-index/${site?.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setSite((prevSite) => {
        return {
          ...prevSite!,
          status: Status.Pending,
        };
      });

      notifications.show('Scraping initiated!', {
        severity: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      notifications.show('An error occurred. Please try again.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
      console.error(error);
    }

    setIsReIndexDialogOpen(false);
  };

  useEffect(() => {
    if (siteStatus === Status.Ready || siteStatus === undefined) {
      return;
    }

    const timerId = setInterval(() => {
      fetchSite();
    }, SITE_POLL_INTERVAL);

    return () => clearInterval(timerId);
  }, [fetchSite, siteStatus]);

  useEffect(() => {
    setLoading(true);

    fetchSite();
  }, [fetchSite]);

  if (loading || !site) {
    return (
      <Box sx={styles.display}>
        {loading ? (
          <CircularProgress />
        ) : !site ? (
          <Typography variant='h5'>Site not found</Typography>
        ) : null}
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }}>
                <Typography variant='h4' gutterBottom>
                  Details
                </Typography>
                <SiteDetailsCard
                  topics={topics}
                  site={site}
                  pagesCount={pagesCount}
                  setSite={setSite}
                  onTopicAdd={onTopicAdd}
                  onTopicRemove={onTopicRemove}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant='h4' gutterBottom>
                  Pages
                </Typography>
                <PagesTable site={site} pagesCount={pagesCount} />
                <Grid container spacing={2} alignItems='center'>
                  <Grid>
                    <Button
                      variant='contained'
                      onClick={() => setIsReIndexDialogOpen(true)}
                      disabled={site.status !== Status.Ready}
                    >
                      Re-index site
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      variant='contained'
                      startIcon={<DeleteIcon />}
                      sx={styles.deleteSiteButton}
                      onClick={() => {
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            size={{ xs: 12, md: 6 }}
            height={'calc(100vh - 150px)'}
            top={0}
            position={'sticky'}
          >
            <ChatPanel
              id={site.id}
              status={site.status}
              theme={site.extensionTheme}
              type='site'
              statusDisplay={<SiteChatStatus status={site.status} />}
              onNewChat={updateChatSessionId}
              chatSessionId={chatSessionId}
              onFetchMessages={fetchMessages}
              onSendMessage={sendMessageResponse}
              onFetchChatSessionsCount={fetchChatSessionsCount}
              onFetchChatSessionsIds={fetchChatSessionsIds}
              onUpdateTheme={updateSiteTheme}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Dialog for delete site */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        dialogMessage='Are you sure you want to delete the site?'
        actionMessage='Delete'
        onAction={handleDelete}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
      {/* Dialog for re-index site */}
      <ConfirmationDialog
        open={isReIndexDialogOpen}
        dialogMessage='Are you sure you want to re-index the site?'
        onAction={handleReIndexing}
        onClose={() => setIsReIndexDialogOpen(false)}
      />
    </>
  );
}

export default SitePage;
