import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Link as RouterLink } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  Card,
  CardContent,
  Link,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  Button,
  Chip,
} from '@mui/material';
import { useNotifications } from '@toolpad/core';

import { styles } from '../styles/stylesheet';
import { useApiClient } from '../context/ApiClientContext';
import { useIndexingProgress } from '../hooks/UseIndexingProgress';
import ConfigureDialog from './ConfigureDialog';
import UpdateSiteDialog from './UpdateSiteDialog';
import ConfirmationDialog from './ConfirmationDialog';
import { SiteConfig, SiteData, Status, TopicData } from '../types';
import AddTopicToSiteDialog from './AddTopicToSiteDialog';

const SiteDetailsCard = ({
  site,
  pagesCount,
  topics,
  setSite,
  onTopicAdd,
  onTopicRemove,
}: {
  topics: TopicData[];
  site: SiteData;
  pagesCount: number;
  setSite: (value: React.SetStateAction<SiteData | undefined>) => void;
  onTopicAdd: (topic: TopicData) => void;
  onTopicRemove: (topic: TopicData) => void;
}) => {
  const [updateSiteDialogOpen, setIsUpdateSiteDialogOpen] = useState(false);
  const [availableTopics, setAvailableTopics] = useState<TopicData[]>([]);
  const [isTopicDialogOpen, setIsTopicDialogOpen] = useState(false);
  const [isVisibilityDialogOpen, setIsVisibilityDialogOpen] = useState(false);
  const [isRemoveTopicDialogOpen, setIsRemoveTopicDialogOpen] = useState(false);
  const [isConfigureDialogOpen, setIsConfigureDialogOpen] = useState(false);
  const [topicToRemove, setTopicToRemove] = useState<TopicData>();
  const [isRegenerateChatIdDialogOpen, setIsRegenerateChatIdDialogOpen] =
    useState(false);
  const [siteFieldToEdit, setSiteFieldToEdit] = useState<{
    fieldName: string;
    display: string;
  }>({ fieldName: '', display: '' });

  const { apiClient } = useApiClient();
  const notifications = useNotifications();

  const { indexedPagesCount, normalizeIndexedPagesCount } = useIndexingProgress(
    site.id,
    apiClient,
    site ? site.status : Status.Pending,
  );

  const handleRemoveTopic = async (topic: TopicData) => {
    try {
      if (!site) {
        return;
      }

      await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/topic/remove`,
        {
          topicId: topic.id,
        },
      );

      onTopicRemove(topic);
      setIsRemoveTopicDialogOpen(false);

      notifications.show('Topic removed successfully!', {
        severity: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error({ error });
      notifications.show('Topic not removed, please try again!', {
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleAddTopic = async (topic: TopicData) => {
    try {
      if (!site) {
        return;
      }

      await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/topic/add`,
        {
          topicId: topic.id,
        },
      );

      onTopicAdd(topic);
      setIsTopicDialogOpen(false);

      notifications.show('Topic added successfully!', {
        severity: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error({ error });
      notifications.show('Topic not added, please try again!', {
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleVisibility = useCallback(async () => {
    try {
      const value = !site!.isPublic;
      const updatedValue = await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}`,
        {
          isPublic: value,
        },
      );

      if (updatedValue.data) {
        setSite((prevSite) => {
          return {
            ...prevSite!,
            isPublic: value,
          };
        });

        setIsVisibilityDialogOpen(false);

        notifications.show('Visibility updated successfully!', {
          severity: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error updating API key:', error);
      notifications.show('Failed to update the visibility. Please try again.', {
        severity: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [apiClient, site, notifications, setSite]);

  const handleUpdateSiteDetails = useCallback(
    async (fieldName: string, fieldValue: string) => {
      try {
        const updatedValue = await apiClient.patch(
          `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}`,
          { [fieldName]: fieldValue },
        );

        if (updatedValue.data) {
          setSite((prevSite) => {
            return {
              ...prevSite!,
              [fieldName]: fieldValue,
            };
          });
          setIsUpdateSiteDialogOpen(false);

          notifications.show('Site details updated successfully!', {
            severity: 'success',
            autoHideDuration: 3000,
          });
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response) {
          const errorCode = error.response.status;
          let errorMessage;

          switch (errorCode) {
            case 422:
              errorMessage =
                error.response.data ||
                'Validation error occurred. Please check your input.';
              break;

            case 500:
              errorMessage =
                error.response.data ||
                'Internal server error. Please try again later.';
              break;

            default:
              errorMessage = `Error Code: ${errorCode} - Something went wrong.`;
          }

          notifications.show(errorMessage, {
            severity: 'error',
            autoHideDuration: 3000,
          });

          if (errorCode === 422) {
            return;
          }
        } else {
          // Handle non-Axios errors
          notifications.show(
            'An unexpected error occurred. Please try again.',
            {
              severity: 'error',
              autoHideDuration: 3000,
            },
          );
        }

        throw error;
      }
    },
    [site.id, apiClient, notifications, setSite],
  );

  const handleConfigure = useCallback(
    async (config: SiteConfig) => {
      try {
        const updatedValue = await apiClient.patch(
          `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}`,
          {
            config: config,
          },
        );

        if (updatedValue.data) {
          setSite((prevSite) => {
            return {
              ...prevSite!,
              config,
            };
          });
          setIsConfigureDialogOpen(false);

          notifications.show('Configured rate limits successfully!', {
            severity: 'success',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error updating API key:', error);
        notifications.show(
          'Failed to configure rate limits. Please try again.',
          {
            severity: 'error',
            autoHideDuration: 3000,
          },
        );
      }
    },
    [site.id, apiClient, notifications, setSite],
  );

  const fetchTopicsForUser = useCallback(async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/available-topics`,
      );

      setAvailableTopics(response.data.topics);
    } catch (error) {
      console.error('Error fetching topics for user:', error);
    }
  }, [apiClient, site.id]);

  useEffect(() => {
    if (isTopicDialogOpen) {
      fetchTopicsForUser();
    }
  }, [isTopicDialogOpen, fetchTopicsForUser]);

  const handleRegeneratePublicChatId = useCallback(async () => {
    try {
      const response = await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/sites/${site.id}/regenerate-public-chat-id`,
      );

      setSite({
        ...site,
        publicChatId: response.data,
      });
      setIsRegenerateChatIdDialogOpen(false);

      notifications.show('Regenerated successfully!', {
        severity: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error regenerating public chat URL:', error);
      notifications.show(
        'Failed to regenerate public chat URL. Please try again.',
        {
          severity: 'error',
          autoHideDuration: 3000,
        },
      );
    }
  }, [apiClient, notifications, setSite, site]);

  return (
    <>
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary='Title'
                secondary={site.title}
                sx={{
                  marginBottom: '8px',
                }}
              />
              <Button
                variant='outlined'
                onClick={() => {
                  setIsUpdateSiteDialogOpen(true);
                  setSiteFieldToEdit({
                    fieldName: 'title',
                    display: 'Site title',
                  });
                }}
                sx={styles.dashboardListItemButton}
              >
                Update
              </Button>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='URL'
                secondary={
                  <Link
                    underline='hover'
                    target='_blank'
                    rel='noopener'
                    href={site.url}
                    display='block'
                    sx={styles.ellipsis}
                  >
                    {site.url}
                  </Link>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='Status'
                secondary={site.status.toUpperCase()}
              />
              {site.status === Status.Indexing && (
                <LinearProgress
                  variant='determinate'
                  color='inherit'
                  value={normalizeIndexedPagesCount(indexedPagesCount)}
                  sx={{
                    ...styles.progressBar,
                    width: '50%',
                  }}
                />
              )}
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='Last Indexed'
                secondary={DateTime.fromISO(site.lastIndexedAt).toRelative()}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary='Page Count' secondary={pagesCount} />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='Visibility'
                secondary={site.isPublic === true ? 'Public' : 'Private'}
                sx={{
                  marginBottom: '8px',
                }}
              />
              <Button
                variant='outlined'
                onClick={() => setIsVisibilityDialogOpen(true)}
                sx={styles.dashboardListItemButton}
              >
                Toggle Visibility
              </Button>
            </ListItem>
            {site.isPublic && (
              <>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary='Public Chat URL'
                    secondary={
                      <Link
                        underline='hover'
                        target='_blank'
                        rel='noopener'
                        component={RouterLink}
                        to={`/p/sites/${site.publicChatId}`}
                        sx={styles.wrappedText}
                      >
                        {`${window.origin}/p/sites/${site.publicChatId}`}
                      </Link>
                    }
                  />
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setIsRegenerateChatIdDialogOpen(true);
                    }}
                    sx={styles.dashboardListItemButton}
                  >
                    Re-generate
                  </Button>
                </ListItem>
              </>
            )}

            <Divider />
            <ListItem>
              <ListItemText
                primary='API Key'
                secondary={`********************* (${site.llmProvider.charAt(0).toUpperCase() + site.llmProvider.slice(1)})`}
              />
              <Button
                variant='outlined'
                onClick={() => {
                  setIsUpdateSiteDialogOpen(true);
                  setSiteFieldToEdit({
                    fieldName: 'llmKey',
                    display: 'API Key',
                  });
                }}
                sx={styles.dashboardListItemButton}
              >
                Update
              </Button>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='Rate Limits'
                secondary={
                  <>
                    User: {site.config.rateLimits.user.points} requests / minute
                    <br />
                    Site: {site.config.rateLimits.site.points} requests / minute
                  </>
                }
              />
              <Button
                variant='outlined'
                onClick={() => setIsConfigureDialogOpen(true)}
                sx={styles.dashboardListItemButton}
              >
                Configure
              </Button>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary='Topics'
                secondary={
                  <Box sx={styles.dashboardTopicBox}>
                    {topics && topics.length > 0
                      ? topics.map((topic) => (
                          <Chip
                            onDelete={() => {
                              setTopicToRemove(topic);
                              setIsRemoveTopicDialogOpen(true);
                            }}
                            key={topic.id}
                            label={topic.name}
                            variant='filled'
                            sx={{ mt: '4px' }}
                          />
                        ))
                      : ''}
                  </Box>
                }
              />
              <Button
                variant='outlined'
                onClick={() => setIsTopicDialogOpen(true)}
                sx={{ ...styles.dashboardListItemButton, width: 115 }}
              >
                Add Topic
              </Button>
            </ListItem>
          </List>
        </CardContent>
      </Card>

      <ConfirmationDialog
        open={isVisibilityDialogOpen}
        dialogMessage={`Are you sure you want to change visibility to ${
          site.isPublic ? 'private' : 'public'
        }?`}
        onAction={handleVisibility}
        onClose={() => setIsVisibilityDialogOpen(false)}
      />
      <UpdateSiteDialog
        open={updateSiteDialogOpen}
        siteField={siteFieldToEdit}
        onClose={() => setIsUpdateSiteDialogOpen(false)}
        onAction={handleUpdateSiteDetails}
      />
      <ConfigureDialog
        values={site.config}
        open={isConfigureDialogOpen}
        onAction={handleConfigure}
        onClose={() => setIsConfigureDialogOpen(false)}
      />
      <AddTopicToSiteDialog
        open={isTopicDialogOpen}
        onClose={() => setIsTopicDialogOpen(false)}
        onAction={handleAddTopic}
        availableTopics={availableTopics}
      />
      <ConfirmationDialog
        open={isRemoveTopicDialogOpen}
        dialogMessage={`Are you sure you want to remove the topic?`}
        onAction={() => handleRemoveTopic(topicToRemove!)}
        onClose={() => setIsRemoveTopicDialogOpen(false)}
      />
      <ConfirmationDialog
        open={isRegenerateChatIdDialogOpen}
        dialogMessage='Are you sure you want to regenerate the Public Chat URL?'
        onAction={handleRegeneratePublicChatId}
        onClose={() => setIsRegenerateChatIdDialogOpen(false)}
      />
    </>
  );
};

export default SiteDetailsCard;
