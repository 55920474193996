import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {
  Box,
  CircularProgress,
  Link,
  ThemeProvider,
  IconButton,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Fade from '@mui/material/Fade';
import InfoIcon from '@mui/icons-material/Info';

import ChatContainer from '../components/ChatContainer';
import { SiteData, Topic, ChatSessionSource, Message } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import { chatsTheme } from '../theme/theme';
import useChatSession from '../hooks/UseChatSession';
import { styles } from '../styles/stylesheet';
import { PAGE_SIZE_FOR_MESSAGES } from '../constants';
import TopicChatStatus from '../components/TopicChatStatus';

const TopicChat = () => {
  const { publicChatId } = useParams();
  const { apiClient } = useApiClient();

  const [topic, setTopic] = useState<Topic>();
  const [loading, setLoading] = useState(true);

  const { chatSessionId, updateChatSessionId } = useChatSession(
    topic?.id,
    ChatSessionSource.Public,
  );

  const fetchTopic = useCallback(async () => {
    try {
      setLoading(true);

      const topicResponse = await apiClient.get(
        `${process.env.REACT_APP_BACKEND_URL}/topics/by-public-chat-id/${publicChatId}`,
      );

      const topic = topicResponse.data.topic;

      const areAllTopicSitesReady =
        topic.sites.length > 0 &&
        topic.sites.every((site: SiteData) => site.status === 'ready');

      topic.status = areAllTopicSitesReady ? 'ready' : 'pending';
      setTopic(topic);
    } finally {
      setLoading(false);
    }
  }, [publicChatId, apiClient]);

  // TODO: Refactor common code
  // TODO: Use /public-chats api when regenerating public chat url for topics
  const sendMessageResponse = async (
    userInput: string,
  ): Promise<axios.AxiosResponse<any, any>> => {
    const response = await apiClient.post(
      `${process.env.REACT_APP_BACKEND_URL}/chats/topics/${topic!.id}`,
      { chatSessionId, userInput, status: topic!.status },
      { headers: { 'Content-Type': 'application/json' } },
    );

    return response;
  };

  const fetchMessages = useCallback(
    async (chatSessionId: string, page: number): Promise<Message[]> => {
      try {
        if (!topic) {
          return [];
        }

        const response = await apiClient.get(
          `${process.env.REACT_APP_BACKEND_URL}/messages/by-chat-session-id/${chatSessionId}?topicId=${topic.id}&pageSize=${PAGE_SIZE_FOR_MESSAGES}&pageNumber=${page}`,
        );

        const messages = response.data.messages.reverse();

        return messages;
      } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
      }
    },
    [apiClient, topic],
  );

  useEffect(() => {
    fetchTopic();
  }, [fetchTopic]);

  useEffect(() => {
    updateChatSessionId();
  }, [updateChatSessionId]);

  if (loading) {
    return (
      <Box sx={styles.display}>
        <CircularProgress />
      </Box>
    );
  }

  if (!topic) {
    return (
      <Box sx={styles.display}>
        <Typography variant='h5'>Topic not found</Typography>
      </Box>
    );
  }

  if (topic.sites.length === 0) {
    return (
      <Box sx={styles.display}>
        <Typography variant='h5'>No site is present for this topic</Typography>
      </Box>
    );
  }

  if (topic.status === 'pending') {
    return (
      <Box sx={styles.display}>
        <Typography variant='h5'>
          One or more site is not ready for this topic
        </Typography>
      </Box>
    );
  }

  return chatSessionId !== '' ? (
    <ThemeProvider theme={chatsTheme}>
      <Grid
        size={{ xs: 12, md: 12 }}
        height='calc(100vh)'
        top={0}
        position='sticky'
      >
        {topic && (
          <ChatContainer
            statusDisplay={<TopicChatStatus topic={topic} />}
            id={topic.id}
            status={topic.status}
            theme={topic.extensionTheme}
            onFetchMessages={fetchMessages}
            onSendMessage={sendMessageResponse}
            chatSessionId={chatSessionId}
            isOpen={true}
            onNewChat={updateChatSessionId}
            chatHeaderProps={{
              isFullScreen: false,
              showTitle: true,
              isTitleClickable: false,
              showNewChat: true,
              subTitle: {
                text: topic.name,
                tooltip: {
                  tooltipProps: {
                    // this should have title and children
                    title: (
                      <>
                        {topic.sites.map((site, index) => (
                          <div key={index}>
                            <Link
                              underline='hover'
                              target='_blank'
                              rel='noopener'
                              href={site.url}
                              sx={{
                                fontSize: '20px',
                              }}
                            >
                              {site.url}
                            </Link>
                          </div>
                        ))}
                      </>
                    ),
                    children: (
                      <IconButton
                        sx={{
                          cursor: 'default',
                        }}
                        disableTouchRipple
                        disableRipple
                      >
                        <InfoIcon />
                      </IconButton>
                    ),
                    slots: {
                      transition: Fade,
                    },
                    slotProps: {
                      transition: { timeout: 600 },
                      tooltip: {
                        sx: {
                          maxWidth: 1000,
                        },
                      },
                    },
                  },
                },
              },
            }}
            source={ChatSessionSource.Public}
          />
        )}
      </Grid>
    </ThemeProvider>
  ) : null;
};

export default TopicChat;
