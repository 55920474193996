import React, { useState } from 'react';
import axios from 'axios';

import { Box, ThemeProvider, Tabs, Tab } from '@mui/material';

import { styles } from '../styles/stylesheet';
import { chatsTheme } from '../theme/theme';
import ChatTabWrapper from './ChatTabWrapper';
import ChatContainer from './ChatContainer';
import ChatbotMessages from './ChatbotMessages';
import ExtensionThemeSwitcher from './ExtensionThemeSwitcher';
import { ChatSessionSource, Status, ExtensionTheme, Message } from '../types';

interface ChatPanelProps {
  id: string;
  status: Status;
  theme: ExtensionTheme;
  type: string;
  onSendMessage: (userInput: string) => Promise<axios.AxiosResponse<any, any>>;
  onFetchMessages: (chatSessionId: string, page: number) => Promise<Message[]>;
  chatSessionId: string;
  onNewChat: () => void;
  onFetchChatSessionsCount: () => Promise<axios.AxiosResponse<any, any>>;
  onFetchChatSessionsIds: (
    pageNumber: number,
  ) => Promise<axios.AxiosResponse<any, any>>;
  onUpdateTheme: (selectedTheme: ExtensionTheme) => Promise<void>;
}

export default function ChatPanel({
  id,
  status,
  theme,
  type,
  onSendMessage,
  onFetchMessages,
  chatSessionId,
  onNewChat,
  onFetchChatSessionsCount,
  onFetchChatSessionsIds,
  onUpdateTheme,
}: ChatPanelProps) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.chatTabContainer}>
      <ThemeProvider theme={chatsTheme}>
        <Box sx={styles.chatTabHeader}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor='primary'
            indicatorColor='primary'
          >
            <Tab label='Chat' />
            <Tab label='Chat Sessions' />
            <Tab label='Chat settings' />
          </Tabs>
        </Box>
        <Box sx={styles.chatTabContent}>
          <ChatTabWrapper value={tabValue} index={0}>
            <ChatContainer
              id={id}
              status={status}
              theme={theme}
              onFetchMessages={onFetchMessages}
              onSendMessage={onSendMessage}
              chatSessionId={chatSessionId}
              isOpen={true}
              onNewChat={onNewChat}
              chatHeaderProps={{
                isFullScreen: false,
                showTitle: false,
                isTitleClickable: false,
                showNewChat: true,
              }}
              source={ChatSessionSource.Dashboard}
              showTrace={true}
            />
          </ChatTabWrapper>
          <ChatTabWrapper value={tabValue} index={1}>
            <ChatbotMessages
              status={status}
              type={type}
              onFetchMessages={onFetchMessages}
              onFetchChatSessionsCount={onFetchChatSessionsCount}
              onFetchChatSessionsIds={onFetchChatSessionsIds}
            />
          </ChatTabWrapper>
          <ChatTabWrapper value={tabValue} index={2}>
            <ExtensionThemeSwitcher
              id={id}
              theme={theme}
              onUpdateTheme={onUpdateTheme}
            />
          </ChatTabWrapper>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
