import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios, { AxiosError, HttpStatusCode } from 'axios';

import { Box } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';

import { styles } from '../styles/stylesheet';
import { ExtensionTheme, Message, Status, ChatSessionSource } from '../types';
import { useApiClient } from '../context/ApiClientContext';
import Chats from './Chats';
import ChatbotHeader from './ChatbotHeader';
import { removeChatUUID } from '../utils/chat';
import ChatInputContainer from './ChatInputContainer';

type HeaderProps = {
  isFullScreen: boolean;
  onChatToggle?: (val: boolean) => void;
  onFullScreenToggle?: (val: boolean) => void;
  showTitle: boolean;
  isTitleClickable: boolean;
  showNewChat: boolean;
  // TODO: Pass component as prop to avoid nested props
  subTitle?: {
    text: string;
    url?: string;
    tooltip?: {
      tooltipProps: {
        title: React.ReactNode;
        children: React.ReactNode;
        slots?: {
          transition: any;
        };
        slotProps?: {
          transition: { timeout: number };
          tooltip: { sx: { maxWidth: number } };
        };
      };
    };
  };
};

// Type for container props
interface ChatContainerProps {
  id: string;
  status: Status;
  theme: ExtensionTheme;
  isOpen: boolean;
  chatHeaderProps: HeaderProps;
  chatSessionId: string;
  source: ChatSessionSource;
  onNewChat?: () => void;
  showTrace?: boolean;
  onSendMessage: (userInput: string) => Promise<axios.AxiosResponse<any, any>>;
  onFetchMessages: (chatSessionId: string, page: number) => Promise<Message[]>;
}

// TODO: Pass id, status, theme as object
function ChatContainer({
  id,
  status,
  theme,
  isOpen,
  chatHeaderProps,
  chatSessionId,
  onNewChat,
  source,
  showTrace = false,
  onSendMessage,
  onFetchMessages,
}: ChatContainerProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  // State is to track when bot is responding
  const [loading, setLoading] = useState(false);

  const isMessagesInitialized = useRef(false);
  const pendingMessagesRef = useRef(0);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const { setMode } = useColorScheme();
  const { apiClient } = useApiClient();

  const scrollToBottom = useCallback((behavior: ScrollBehavior = 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior, block: 'nearest' });
    }
  }, []);

  const handleNewChat = useCallback(
    async (messageId: string) => {
      if (!onNewChat || !id) {
        return;
      }

      await apiClient.patch(
        `${process.env.REACT_APP_BACKEND_URL}/messages/close-chat-session/${chatSessionId}?latestMessageId=${messageId}`,
        { isClosed: true },
      );

      removeChatUUID(source, id);
      onNewChat();
      setMessages([]);
    },
    [onNewChat, setMessages, id, source, apiClient, chatSessionId],
  );

  const checkLatestMessage = useCallback(
    async (messages: Message[]) => {
      if (!messages.length) {
        return;
      }
      const latestMessage = messages[messages.length - 1];
      const latestTimestamp = Date.parse(latestMessage.timeStamp);
      const currentTime = Date.now();
      const oneDayAgo = currentTime - 24 * 60 * 60 * 1000; // One day in milliseconds

      if (latestTimestamp < oneDayAgo) {
        try {
          handleNewChat(latestMessage.messageId);
        } catch (error) {
          console.error('Error clearing messages:', error);
        }
      } else {
        setMessages((prevMessages) => [...messages, ...prevMessages]);
      }
    },
    [handleNewChat],
  );

  useEffect(() => {
    const initialize = async () => {
      const fetchedMessages = await onFetchMessages(chatSessionId, 1);

      // Check if latest message is older than 24 hours
      checkLatestMessage(fetchedMessages);
    };

    if (
      chatSessionId !== '' &&
      !isMessagesInitialized.current &&
      status === Status.Ready
    ) {
      isMessagesInitialized.current = true;
      initialize();
    }
  }, [onFetchMessages, chatSessionId, status, checkLatestMessage]);

  const handleSendMessage = async (question: string) => {
    const userInput = question.trim();
    if (!userInput) {
      return;
    }

    const tempMessageId = `temp-${Date.now()}`;

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: userInput,
        isUser: true,
        messageId: tempMessageId,
        feedback: null,
        isError: false,
        timeStamp: Date.now().toString(),
      },
    ]);
    pendingMessagesRef.current += 1; // Increment counter
    setLoading(true);
    setTimeout(() => scrollToBottom('smooth'), 0);

    try {
      const response = await onSendMessage(userInput);

      setMessages((prevMessages) => {
        // Update ID once response is received
        const updatedMessages = prevMessages.map((msg) =>
          msg.messageId === tempMessageId
            ? { ...msg, messageId: response.data.messageId }
            : msg,
        );

        return [
          ...updatedMessages,
          {
            text: response.data.answer,
            isUser: false,
            messageId: response.data.messageId,
            feedback: null,
            isError: false,
            timeStamp: Date.now().toString(),
          },
        ];
      });

      setTimeout(() => scrollToBottom('smooth'), 0);
    } catch (error) {
      console.error('Error fetching bot response:', error);

      let errMessage =
        'Sorry, an error occurred. Please try again after some time.';
      if ((error as AxiosError).status === HttpStatusCode.TooManyRequests) {
        errMessage = (error as any).response.data.error;
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: errMessage,
          isUser: false,
          messageId: `error-${Date.now()}`,
          feedback: null,
          isError: true,
          timeStamp: Date.now().toString(),
        },
      ]);
    } finally {
      pendingMessagesRef.current -= 1;

      // Update loading based on pending messages count
      setLoading(pendingMessagesRef.current > 0);
    }
  };

  const loadMessages = useCallback(
    async (pageNumber: number): Promise<boolean> => {
      if (!hasMoreMessages) {
        return false;
      }

      try {
        const newMessages = await onFetchMessages(chatSessionId, pageNumber);
        if (newMessages.length > 0) {
          setMessages((prevMessages) => [...newMessages, ...prevMessages]);
        } else {
          setHasMoreMessages(false);
        }
      } catch (error) {
        console.error('Failed to load messages:', error);
        throw error;
      }

      return messages.length > 0;
    },
    [hasMoreMessages, onFetchMessages, messages.length, chatSessionId],
  );

  useEffect(() => {
    if (!theme || source === ChatSessionSource.Dashboard) {
      return;
    }

    setMode(theme);
  }, [setMode, theme, source]);

  return (
    <Box
      sx={{
        ...styles.chatContainer,
        display: isOpen ? 'flex' : 'none',
      }}
    >
      <ChatbotHeader
        {...chatHeaderProps}
        onNewChat={handleNewChat}
        messageId={messages[messages.length - 1]?.messageId}
      />
      <Chats
        isOpen={isOpen}
        messages={messages}
        loading={loading}
        disableFeedback={false}
        messagesEndRef={messagesEndRef}
        onNextPage={loadMessages}
        status={status}
        chatSessionId={chatSessionId}
        showTrace={showTrace}
      />
      {status === Status.Ready && (
        <ChatInputContainer
          onMessageSend={handleSendMessage}
          isSendDisabled={
            messages.length > 0
              ? messages[messages.length - 1].messageId === '-1'
              : false
          }
        />
      )}
    </Box>
  );
}

export default ChatContainer;
